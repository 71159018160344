.quill {
  background-color: white;
  .ql-toolbar {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .ql-container {
    border-radius: 0 0 0.5rem 0.5rem;
  }
}
.message-input {
  padding: 0.5em;
  .ql-container {
    border-radius: 0.5rem;
    margin-bottom: 0.25em;
  }
}
.quill-content {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  a {
    color: #0000ee;
    text-decoration: underline;
  }
  blockquote {
    font-style: italic;
    color: #555;
    border-left: 4px solid #ccc;
    margin: 1em 0;
    padding: 0.5em 1em;
    background-color: #f9f9f9;
  }
}

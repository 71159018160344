// @import './variables.scss';
// @import './mixins.scss';
@import './pallette.module.scss';

.forgot-pass {
  .ant-form-item {
    margin-bottom: 0.5em;
  }
  .ant-radio-button-wrapper-checked {
    color: black !important;
    background-color: transparent !important;
  }
  .ant-radio-button-wrapper {
    height:  4em;
    width: 100% !important;
    line-height: 1.15;
    display: flex;
    align-items: center;
  }
  .ant-radio-group {
    width: 100% !important;

  }
  .ant-space {
    width: 100% !important;

  }
}

.complete_profile_toaster {
  width: 80%;
  .message {
    &-container {
      display: flex;
      align-items: center;
    }

    &-text {
      flex: 1;
    }
  }
  svg {
    cursor: pointer;
    margin-left: 1em;
  }
}

.auth-divider {
  .ant-divider-inner-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: gray;
  }
}

.date-divider {
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    // content: none;
    border-block-start: 0;
  }
  .ant-divider-inner-text {
    background-color: white;
    border-radius: 1rem;
    margin: 0.5em;
  }
}

.form {
  &-section-divider {
    // .ant-divider-inner-text {
    // }
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
      content: none;
    }
  }

  &-date-picker {
    width: 100%;
  }
  &-radio-button-image {
    img {
      margin-left: 1em; //image has white space at right, I am lazy to download gimp
      width: 4em;
      height: 8em;
    }
    .ant-radio-button-wrapper {
      padding: 0.5em;
      // justify-content: center;
      // align-items: center;
      // width: 5em;
      height: auto;
      text-align: center;
    }
  }
}

.feed-item {
  // margin: 0 8em;
  .ant-card-meta {
    margin-bottom: 0.25em;
  }
  .ant-card-meta-title {
    margin: 0 !important;
  }
}

@media (orientation: landscape) {
  .feed-item {
    margin: 0 15em;
  }
}

body,
#root,
.ant-layout {
  position: absolute;
  inset: 0;
}

.cloud {
  position: absolute;
  inset: 0;
  background-image: url('/../public/images/welcome-bg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.cloud-background {
  background-image: url('/../public/images/welcome-bg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.welcome {
  .ant-layout {

    &-header {
      justify-content: center;
      display: flex;
      height: auto;
      background-color: transparent;
      line-height: normal;
      img {
        margin-top: 3em;
        margin-bottom: 3em;
        width: 100%;
      }
    }
    &-content {
      padding: 0 1.25em 0 1.25em;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    &-footer {
      padding: 0.5em;
    }
  }
  .external-auth-btn-container {
    margin-bottom: 0.5em;
  }
}

.black-header-actions {
  display: flex;
  button {
    margin-left: 1em;
  }
}

.with-black-header-layout {
  // height: 100vh;
  .ant-layout {
    &-header {
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      .ant-btn-link {
        color: white;
      }
    }
    &-content {
      padding: 0.5em;
      overflow-y: scroll;
      .infinite-scroll-component{
        overflow: hidden !important;
      }
    }
    &-footer {
      position: sticky;
      bottom: 0;
      padding: 0;
      z-index: 999;
      .two-buttons {
        margin: 1em;
      }
    }
  }
}

.standalone-layout {
  .avatar {
    .ant-upload {
      border: 0 !important;
      background-color: transparent !important;
      min-width: 100%;
    }
    .ant-upload-list {
      justify-content: center;
    }

    .ant-flex {
      width: 100%;
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card {
      width: auto;
      height: auto;
    }
  }
  .ant-layout {
    margin: 1em;
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-position: center;
    // flex: 1
  }

  .ant-layout-content {
    padding: 0.5em;
  }
  .content-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-layout-header {
    background-color: transparent;
    height: fit-content;
    padding: 0.25em 0.5em 0 0.5em;
  }

  .ant-upload-select {
    display: block;
  }

  .ant-layout-footer {
    padding: 0.5em;
  }
}

@import '~antd/dist/reset.css';
@import './utils/theme/antd_overwrite.scss';
@import './utils/theme/quill_overwrite.scss';
@import './utils/theme/antd_customized_components.scss';
@import './utils/theme/pallette.module.scss';
html,
body {
  overscroll-behavior: none;
}

a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  margin-bottom: 0.5em;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  color: $primaryTextColor;
}
h5,
h6 {
  color: $secondaryTextColor;
  margin: 0;
}

@import './pallette.module.scss';

.ant-btn-primary {
  box-shadow: none;
}
.ant-avatar {
  background: $secondaryBackground;
}
.ant-tour {
  max-width: 20em;
}

.ant-list-item {
  &-action {
    margin-inline-start: 0 !important;
  }
}
.ant-drawer {
  &-content {
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
  }
}
.ant-drawer-header::before {
  content: '';
  position: absolute;
  margin-top: 0.5em;
  top: 0;
  left: 50%;
  width: 3em;
  height: 0.25em;
  background-color: gray;
  transform: translateX(-50%);
  border-radius: 0.125em; /* This will make the edges rounded */
}

.ant-tabs {
  &-nav-list {
    width: 100%;
  }
  &-ink-bar-animated {
    min-height: 0.25em;
    border-radius: 0.5em 0.5em 0 0;
  }

  &-tab {
    justify-content: center;
    width: 100%;
  }
}
